import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function VentiladorC(props) {
  const { nodes, materials } = useGLTF('/Materiales/Cafeteria/ventiladorcaferteria.glb')
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria001.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria002.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria003.geometry}
        material={nodes.ventilador_cafeteria003.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria004.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria005.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria006.geometry}
        material={nodes.ventilador_cafeteria006.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria007.geometry}
        material={nodes.ventilador_cafeteria007.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria008.geometry}
        material={nodes.ventilador_cafeteria008.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria009.geometry}
        material={nodes.ventilador_cafeteria009.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria010.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria011.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria012.geometry}
        material={nodes.ventilador_cafeteria012.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria013.geometry}
        material={nodes.ventilador_cafeteria013.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria014.geometry}
        material={nodes.ventilador_cafeteria014.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria015.geometry}
        material={nodes.ventilador_cafeteria015.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria016.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria017.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria018.geometry}
        material={nodes.ventilador_cafeteria018.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria019.geometry}
        material={nodes.ventilador_cafeteria019.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria020.geometry}
        material={nodes.ventilador_cafeteria020.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria021.geometry}
        material={nodes.ventilador_cafeteria021.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria022.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria023.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria024.geometry}
        material={nodes.ventilador_cafeteria024.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria025.geometry}
        material={nodes.ventilador_cafeteria025.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria026.geometry}
        material={nodes.ventilador_cafeteria026.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria027.geometry}
        material={nodes.ventilador_cafeteria027.material}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ventilador_cafeteria028.geometry}
        material={materials.aspa1}
        position={[-0.071, 1.415, -0.209]}
        rotation={[Math.PI / 2, 0, -1.658]}
        scale={[1.532, 1.722, 1.213]}
      />
    </group>
  )
}

useGLTF.preload('/Materiales/Cafeteria/ventiladorcaferteria.glb')