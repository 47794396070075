import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Enfriador(props) {
  const { nodes, materials } = useGLTF('/Materiales/Cafeteria/enfriadorgaseosas.glb')
  return (
    <group {...props} dispose={null}>
      <group position={[0.108, 0.039, -0.078]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BACK002.geometry}
          material={materials['Editable Brushed  Aluminium']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube008.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube010.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube498.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube499.geometry}
          material={materials.Metal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube500.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube501.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube502.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube503.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube504.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube506.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.interior002.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.MARCO010.geometry}
          material={materials['Rough Cast Steel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P001.geometry}
          material={nodes.P001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P002.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P003.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P004.geometry}
          material={nodes.P004.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P005.geometry}
          material={nodes.P005.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P006.geometry}
          material={nodes.P006.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P007.geometry}
          material={nodes.P007.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P008.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P009.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P010.geometry}
          material={nodes.P010.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P011.geometry}
          material={nodes.P011.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P012.geometry}
          material={nodes.P012.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P013.geometry}
          material={nodes.P013.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P014.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P015.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P016.geometry}
          material={nodes.P016.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P017.geometry}
          material={nodes.P017.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P018.geometry}
          material={nodes.P018.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P019.geometry}
          material={nodes.P019.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P020.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P021.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P022.geometry}
          material={nodes.P022.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P023.geometry}
          material={nodes.P023.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P024.geometry}
          material={nodes.P024.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P025.geometry}
          material={nodes.P025.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P026.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P027.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P028.geometry}
          material={nodes.P028.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P029.geometry}
          material={nodes.P029.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P030.geometry}
          material={nodes.P030.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P031.geometry}
          material={nodes.P031.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P032.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P033.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P034.geometry}
          material={nodes.P034.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P035.geometry}
          material={nodes.P035.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P036.geometry}
          material={nodes.P036.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P073.geometry}
          material={nodes.P073.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P074.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P075.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P076.geometry}
          material={nodes.P076.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P077.geometry}
          material={nodes.P077.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P078.geometry}
          material={nodes.P078.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P079.geometry}
          material={nodes.P079.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P080.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P081.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P082.geometry}
          material={nodes.P082.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P083.geometry}
          material={nodes.P083.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P084.geometry}
          material={nodes.P084.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P085.geometry}
          material={nodes.P085.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P086.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P087.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P088.geometry}
          material={nodes.P088.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P089.geometry}
          material={nodes.P089.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P090.geometry}
          material={nodes.P090.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P091.geometry}
          material={nodes.P091.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P092.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P093.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P094.geometry}
          material={nodes.P094.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P095.geometry}
          material={nodes.P095.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P096.geometry}
          material={nodes.P096.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P097.geometry}
          material={nodes.P097.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P098.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P099.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P100.geometry}
          material={nodes.P100.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P101.geometry}
          material={nodes.P101.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P102.geometry}
          material={nodes.P102.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P103.geometry}
          material={nodes.P103.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P104.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P105.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P106.geometry}
          material={nodes.P106.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P107.geometry}
          material={nodes.P107.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P108.geometry}
          material={nodes.P108.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P109.geometry}
          material={nodes.P109.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P110.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P111.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P112.geometry}
          material={nodes.P112.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P113.geometry}
          material={nodes.P113.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P114.geometry}
          material={nodes.P114.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P115.geometry}
          material={nodes.P115.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P116.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P117.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P118.geometry}
          material={nodes.P118.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P119.geometry}
          material={nodes.P119.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P120.geometry}
          material={nodes.P120.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P121.geometry}
          material={nodes.P121.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P122.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P123.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P124.geometry}
          material={nodes.P124.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P125.geometry}
          material={nodes.P125.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P126.geometry}
          material={nodes.P126.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P127.geometry}
          material={nodes.P127.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P128.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P129.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P130.geometry}
          material={nodes.P130.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P131.geometry}
          material={nodes.P131.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.P132.geometry}
          material={nodes.P132.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.parante002.geometry}
          material={materials['Rough Cast Steel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane.geometry}
          material={nodes.Plane.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.PUNTERA002.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube190.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube190_1.geometry}
          material={materials['Anti-slip plate - square']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube190_2.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube192.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube192_1.geometry}
          material={materials['Anti-slip plate - square']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube192_2.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube194.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube194_1.geometry}
          material={materials['Anti-slip plate - square']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube194_2.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube399.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube399_1.geometry}
          material={materials['Anti-slip plate - square']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube399_2.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041.geometry}
          material={materials.Metal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube041_1.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube046.geometry}
          material={materials.Metal}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube046_1.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube027.geometry}
          material={materials.Plastic}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube027_1.geometry}
          material={materials['luz heladeras']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006.geometry}
          material={materials['00 burlete gris']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane006_1.geometry}
          material={materials['temp heladera']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube047.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube047_1.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube047_2.geometry}
          material={materials['Brushed Metal Generator']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube048.geometry}
          material={materials['plastic dark']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube048_1.geometry}
          material={materials.Glass}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube048_2.geometry}
          material={materials['Brushed Metal Generator']}
        />
      </group>
      <group position={[0.108, 0.039, -0.078]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas.geometry}
          material={materials['black metal cellar']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_1.geometry}
          material={materials['aluminum cellar']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_2.geometry}
          material={materials['black plastic cellar']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_3.geometry}
          material={materials.Rubber}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_4.geometry}
          material={materials['cellar glass']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_5.geometry}
          material={materials['Refrigerator panel']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_6.geometry}
          material={materials['Refrigerator letters']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_7.geometry}
          material={materials['Wine House crome']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_8.geometry}
          material={materials['black plastic']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_9.geometry}
          material={materials['bottle cellar']}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Adega_Electrolux_24_Garrafas_10.geometry}
          material={materials['wine cellar']}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/Materiales/Cafeteria/enfriadorgaseosas.glb')